import * as React from "react";
import * as _ from "lodash";
import {
    Button,
    Card,
    CardBody,
    CardText,
    CardTitle,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGlobe,
    faTrashAlt,
    faArrowAltCircleUp,
    faMapMarkerAlt,
    faCog,
    faPlusCircle,
    faPencilAlt,
    faBars,
    faQuestionCircle,
    faDownload,
    faUpload,
    faLayerGroup,
    faMap,
    faTimes,
    faCrosshairs,
    faEllipsis,
} from '@fortawesome/free-solid-svg-icons';

import { formatLatLonPlain, formatBearing } from "app/lib/formatting";
import { WorkspaceContextProvider, useWorkspaceContext } from "./context";
import MapPane, { MapProvider, useDefaultMap, BASE_LAYERS } from "./map";
import WorkspaceToolbar from "./toolbar";
import WorkspaceStatusbar from "./statusbar";


export default function WorkspaceViewWrapper({ workspaceId }) {
    // This wrapper handles all the data loading etc
    workspaceId = parseInt(workspaceId, 10);
    return (
        <WorkspaceContextProvider workspaceId={workspaceId}>
            <WorkspaceView />
        </WorkspaceContextProvider>
    );
}


function WorkspaceView() {
    // This is a pure UI component
    return (
        <>
            <MapProvider>
                <WorkspaceToolbar />
                <div className="flex-grow-1 d-flex flex-column position-relative">
                    <MapPane />
                    <ReticleOverlay />
                    <MapOverlay />
                </div>
            </MapProvider>
        </>
    );
}


function ReticleOverlay() {
    const workspaceContext = useWorkspaceContext();
    const reticleSettings = workspaceContext.localState?.viewSettings?.reticle;
    // console.log("Reticle settings", reticleSettings);

    if (!reticleSettings) {
        return null;  // TODO WARNING
    }

    const { show, color } = reticleSettings;

    if (!show) {
        return null;
    }

    const style = {
        pointerEvents: "none",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    };
    return (
        <div style={style}>
            <svg width="100%" height="100%">
                <line x1="50%" y1="0" x2="50%" y2="100%" strokeWidth="1" stroke={color} />
                <line x1="0" y1="50%" x2="100%" y2="50%" strokeWidth="1" stroke={color} />

                <line x1="50%" y1="0" x2="50%" y2="20%" strokeWidth="3" stroke={color} />
                <line x1="50%" y1="80%" x2="50%" y2="100%" strokeWidth="3" stroke={color} />

                <line x1="0" y1="50%" x2="20%" y2="50%" strokeWidth="3" stroke={color} />
                <line x1="80%" y1="50%" x2="100%" y2="50%" strokeWidth="3" stroke={color} />
            </svg>
        </div>
    );
}


function MapOverlay() {

    const [leftPaneVisible, setLeftPaneVisible] = React.useState(false);
    const [rightPaneVisible, setRightPaneVisible] = React.useState(false);

    const style = {
        pointerEvents: "none",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    };

    const paneStyle = {
        width: "30%",
        maxWidth: "600px",
        pointerEvents: "auto",
    };

    const renderLeftPane = () => {
        if (!leftPaneVisible) {
            return null;
        }
        return (
            <div style={paneStyle} className="p-2 bg-dark">
                Left pane
            </div>
        );
    };

    const renderRightPane = () => {
        if (!rightPaneVisible) {
            return null;
        }
        return (<div style={paneStyle}>Right pane</div>);
    };

    return (
        <div id="map-overlay" style={style} className="d-flex flex-row">
            {renderLeftPane()}

            <div className="flex-grow-1 d-flex flex-column position-relative">

                {false && <div className="d-flex flex-row">
                    <div><Button>TOP LEFT</Button></div>
                    <div className="flex-grow-1"></div>
                    <div><Button>TOP CENTER</Button></div>
                    <div className="flex-grow-1"></div>
                    <div><Button>TOP RIGHT</Button></div>
                </div>}

                <WorkspaceStatusbar />
                <MapOverlayLayerSelector />

            </div>

            {renderRightPane()}
        </div>
    );
}


function MapOverlayLayerSelector() {

    const [selectedPane, selectPane] = React.useState(null);

    const getSelectorProps = (name) => {
        return {
            active: selectedPane === name,
            onClick: () => selectPane(prev => prev === name ? null : name),
        };
    };

    const BasePane = ({ children, title }) => {
        return (
            <div style={{ pointerEvents: "auto" }}>
                <Card style={{ height: 400, width: 400 }}>
                    <CardBody>
                        {!!title && <CardTitle>
                            <h4>{title}</h4>
                        </CardTitle>}
                        <CardText>
                            {children}
                        </CardText>
                    </CardBody>
                </Card>
            </div>
        );
    };

    const LayerSelectorPane = () => {
        return (
            <BasePane title="Layers">Layer selector</BasePane>
        );
    }

    const BaseMapSelectorPane = () => {
        return (
            <BasePane title="Base map">Base map selector</BasePane>
        );
    }

    return (
        <div style={{ position: "absolute", right: 0, bottom: 0 }}>
            <div className="m-1">
                {selectedPane === "layer" && <LayerSelectorPane />}
                {selectedPane === "base-map" && <BaseMapSelectorPane />}
            </div>
            <div className="m-1" style={{ textAlign: "right" }}>
                <Button
                    {...getSelectorProps("layer")}
                    className="ms-1"
                    style={{ pointerEvents: "auto" }}
                >
                    <FontAwesomeIcon icon={faLayerGroup} />{" "}
                    Layers
                </Button>
                <Button
                    {...getSelectorProps("base-map")}
                    className="ms-1"
                    style={{ pointerEvents: "auto" }}
                >
                    <FontAwesomeIcon icon={faMap} />{" "}
                    Base map
                </Button>
            </div>
        </div>
    );
}

import * as React from "react";
import { Alert } from "reactstrap";


export default function LoadingMessage({ message, retry }) {
    return (
        <Alert color="info">
            <div>{message}</div>
        </Alert>
    );
}


LoadingMessage.defaultProps = {
    message: "Loading...",
};

import * as React from "react";
import { Alert, Button } from "reactstrap";


export default function ErrorMessage({ message, retry }) {
    return (
        <Alert color="danger">
            <div className="d-flex justify-content-between">
                <div>{message}</div>
                <div>
                    <Button color="danger" onClick={() => retry()}>
                        Retry
                    </Button>
                </div>
            </div>
        </Alert>
    );
}


ErrorMessage.defaultProps = {
    message: "An error occurred",
};

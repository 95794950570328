import * as React from "react";


export function createDataStore({
    loader,
    dumper,
    displayName = "DatastoreContext",
} = {}) {

    const DatastoreContext = React.createContext(null);
    DatastoreContext.displayName = displayName;

    function DataStoreProvider({ initialState = null, children }) {
        let [state, setState] = React.useState(null);

        React.useEffect(
            () => setState(loader ? loader(initialState) : initialState),
            [initialState],
        );

        React.useEffect(
            () => {
                if (dumper) {
                    dumper(state);
                }
            },
            [state],
        );

        return (
            <DatastoreContext.Provider value={{ state, setState }}>
                {children}
            </DatastoreContext.Provider>
        );
    }

    function useDataStore() {
        // Returns { state, setState } object
        return React.useContext(DatastoreContext);
    }

    return [DataStoreProvider, useDataStore];
}


const [ DataStoreProvider, useDataStore ] = createDataStore();
export { DataStoreProvider, useDataStore };

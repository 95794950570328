import * as React from "react";

import WorkspaceSelector from "./workspace-selector";
import ModeSelector from "./mode-selector";
import LayerSelector from "./layer-selector";
import LayerMenu from "./layer-menu";
import ReticleSettingsButton from "./reticle-settings";



export default function WorkspaceToolbar() {
    const className = [
        "bg-dark-100",
        "p-1",
        "d-flex",
        "flex-row",
        "align-items-center",
        "justify-content-center",
    ].join(" ");
    return (
        <div className={className}>

            <span className="me-1">
                <WorkspaceSelector />
            </span>
            <span className="me-1">
                <ModeSelector />
            </span>
            <span className="me-1">
                <LayerSelector />
            </span>
            <span className="me-1">
                <LayerMenu />
            </span>

            <div className="flex-grow-1" />

            <div className="ms-1">
                <ReticleSettingsButton />
            </div>

        </div>
    );
}

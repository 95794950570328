import * as React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "images/logo.svg";
import { PRODUCT_TITLE } from "app/config";
import { useTokenContext } from "app/ui/token-context";


export default function SiteHeader() {
    return (
        <div className="d-flex flex-row align-items-center p-1">
            <div className="fs-3 me-2">
                <Link to="/" className="text-decoration-none">
                    <Logo width="1em" height="1em" viewBox="0 0 512 512" />
                </Link>
            </div>
            <div>
                <h1 className="fs-3 m-0">
                    <Link to="/" className="text-decoration-none">
                        {PRODUCT_TITLE}
                    </Link>
                </h1>
            </div>
            <div className="flex-grow-1"></div>
            <div>
                <UserMenu />
            </div>
        </div>
    );
}


function UserMenu() {
    const { tokenInfo } = useTokenContext();
    if (!tokenInfo) {
        return null;  // Still loading
    }

    if (!tokenInfo.isAuthenticated) {
        return (
            <>
                <Button color="primary" tag={Link} to="/signup">
                    Sign up
                </Button>
                <Button color="link" tag={Link} to="/signup">
                    Login
                </Button>
            </>
        );
    }
    return (
        <span>
            <Link to="/account">
                Your account
            </Link>
        </span>
    );
}

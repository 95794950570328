export default {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "title": "One"
            },
            "geometry": {
                "coordinates": [
                    -6.457886354018825,
                    54.990010161046484
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "title": "Two"
            },
            "geometry": {
                "coordinates": [
                    -9.646034025389696,
                    54.107308663422856
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "title": "Three"
            },
            "geometry": {
                "coordinates": [
                    -9.155549768255554,
                    51.679942681740954
                ],
                "type": "Point"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "title": "My line"
            },
            "geometry": {
                "coordinates": [
                    [
                        -6.713478127437867,
                        52.34441168389887
                    ],
                    [
                        -80.78554151457564,
                        26.559511214629254
                    ]
                ],
                "type": "LineString"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "title": "My line 2"
            },
            "geometry": {
                "coordinates": [
                    [
                        100.0,
                        60.0
                    ],
                    [
                        -100.0,
                        60.0
                    ]
                ],
                "type": "LineString"
            }
        },
    ]
};

import * as React from "react";
import { Map, MapProvider, useMap, Layer, Source } from "react-map-gl";
import maplibregl from "maplibre-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

import "maplibre-gl/dist/maplibre-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import makeGeodesicGeojson from "app/lib/geo/geojson-geodesic";
import EXAMPLE_GEOJSON_DATA from "./data/geojson1.js";

export { MapProvider, useMap };


const DEFAULT_MAP_ID = "default";


// See this for interpolating lines on geodesic
// https://maplibre.org/maplibre-gl-js-docs/example/animate-point-along-route/


export default function MapPane() {

    const GEOJSON_DATA = makeGeodesicGeojson(EXAMPLE_GEOJSON_DATA);
    // const GEOJSON_DATA = EXAMPLE_GEOJSON_DATA;
    // console.log("DATA", GEOJSON_DATA);

    return (
        <Map
            id={DEFAULT_MAP_ID}
            mapLib={maplibregl}
            initialViewState={{
                longitude: 0,
                latitude: 45,
                zoom: 4,
                // center: [...] ?
            }}
            mapStyle={MAPSTYLE}
        >
            <MapDrawPlugin />

            <Source
                id="example-points-data"
                type="geojson"
                data={GEOJSON_DATA}
            >
                <Layer
                    id="example-points-circle"
                    key="example-points-circle"
                    type="circle"
                    source="example-points-data"
                    paint={{
                        "circle-color": [ "coalesce", ["get", "color"], "#3F51B5" ],
                        // "circle-stroke-width": 1.5,
                        // "circle-stroke-color": "white",
                        "circle-radius": 12,
                    }}
                    filter={["==", ["geometry-type"], "Point"]}
                />
                <Layer
                    id="example-points-label"
                    key="example-points-label"
                    type="symbol"
                    source="example-points-data"
                    paint={{
                        "text-color": "#fff",
                    }}
                    layout={{
                        "text-field": ["get", "title"],
                        "text-font": ["Noto Sans", "Open Sans", "Roboto"],
                        "text-anchor": "center",
                        "text-size": 14,
                        "text-max-width": 2,
                    }}
                    filter={["==", ["geometry-type"], "Point"]}
                />
                <Layer
                    id="example-line"
                    key="example-line"
                    type="line"
                    source="example-points-data"
                    paint={{
                        "line-color": "#E91E63",
                        "line-width": 3,
                    }}
                    layout={{
                        // "text-field": ["get", "title"],
                        // "text-font": ["Noto Sans", "Open Sans", "Roboto"],
                        // "text-anchor": "center",
                        // "text-size": 14,
                        // "text-max-width": 2,
                    }}
                    filter={["==", ["geometry-type"], "LineString"]}
                />
            </Source>

        </Map>
    );
}


export function useDefaultMap() {
    return useMap()[DEFAULT_MAP_ID];
}


export const BASE_LAYERS = [
    {
        id: "osm",
        title: "Open Street Map",
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        source: {
            type: "raster",
            tiles: [
                "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
                "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
                "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
            ],
            tileSize: 256,
        },
        minzoom: 0,
        maxzoom: 19,
    },
    {
        id: "stamen",
        title: "Stamen Watercolor",
        attribution: 'Map tiles by <a target="_top" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>',
        source: {
            type: "raster",
            tiles: [
                "https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"
            ],
            tileSize: 256,
            minzoom: 0,
            maxzoom: 22,
        },
    },
];


const BASE_LAYER_SOURCES = Object.fromEntries(
    BASE_LAYERS.map(config => [config.id, config.source])
);


export const LAYER_SOURCES = {
    ...BASE_LAYER_SOURCES,
};


// mapStyle="https://demotiles.maplibre.org/style.json"
const MAPSTYLE = {
    "version": 8,
    "sources": LAYER_SOURCES,
    "glyphs": "https://api.maptiler.com/fonts/{fontstack}/{range}.pbf?key=4TO7i22tMuT1472XPTYA",
    "layers": [
        {
            "id": "base-map",
            "type": "raster",
            "source": "osm",
        }
    ]
};


function MapDrawPlugin() {
    const map = useDefaultMap();

    React.useEffect(() => {

        // FIXME why is this being loaded twice
        map.on('load', () => {

            // Only add the plugin once
            if (map._drawPlugin) {
                return;
            }

            //console.log("Adding draw control to map", map);
            const draw = new MapboxDraw({
                // displayControlsDefault: false,
            });
            map._drawPlugin = draw;
            map.addControl(draw);
        });

    }, [map]);

    return null;
}

import * as React from "react";
import * as _ from "lodash";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh } from '@fortawesome/free-solid-svg-icons';

import { useWorkspaceContext } from "../context";



export default function WorkspaceModeSelector() {
    const [isOpen, setOpen] = React.useState(false);
    const workspaceContext = useWorkspaceContext();
    // console.log("Workspace data", workspaceContext.data);
    return (
        <Dropdown
            isOpen={isOpen}
            toggle={() => setOpen(st => !st)}
            direction="down"
        >
            <DropdownToggle caret>
                <FontAwesomeIcon icon={faTh} />{" "}
                <span
                    style={{
                        // minWidth: 100,
                        // maxWidth: 200,
                        width: 150,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                        margin: "inherit",
                        lineHeight: "1.3em",
                        height: "1em",
                    }}
                    className="text-start m-0 p-0"
                >
                    {workspaceContext.data?.metadata?.displayName
                    || <span className="text-muted">Untitled workspace</span>}
                    {" "}
                </span>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem header>Header</DropdownItem>
                <DropdownItem>Some Action</DropdownItem>
                <DropdownItem text>Dropdown Item Text</DropdownItem>
                <DropdownItem disabled>Action (disabled)</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>All workspaces</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

import * as React from "react";
import * as _ from "lodash";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';

import { useWorkspaceContext } from "../context";


export default function ReticleSettingsButton() {
    const workspaceContext = useWorkspaceContext();
    const states = [
        {show: false, color: "#000"},
        {show: true, color: "#000"},
        {show: true, color: "#f0f"},
    ];

    const deepFind = (haystack, needle) => {
        for (let idx = 0; idx < haystack.length; idx++) {
            if (_.isEqual(haystack[idx], needle)) {
                return idx;
            }
        }
        return -1;
    };

    const cycleState = (states, currentState) => {
        let index = deepFind(states, currentState);
        let nextIndex = (index + 1) % states.length;
        return states[nextIndex];
    }

    const onClick = () => {
        workspaceContext.setLocalState(state => {
            let current = state.viewSettings?.reticle;
            return {
                ...state,
                viewSettings: {
                    ...state.viewSettings,
                    reticle: cycleState(states, current),
                },
            };
        });
    };

    const currentState = workspaceContext.localState?.viewSettings?.reticle || {};
    return (
        <Button onClick={onClick} active={currentState.show} title="Cycle reticle settings">
            <div
                style={{ color: currentState.show ? currentState.color : "#fff" }}
                className="d-inline-block"
            >
                <FontAwesomeIcon icon={faCrosshairs} />
            </div>
        </Button>
    );
}

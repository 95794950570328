import { ApolloProvider } from "@apollo/client";
import { createApolloClient } from "./apollo-client";
import { getAuthToken } from "./auth-token";


export default function ConfiguredApolloProvider({ children }) {
    const accessToken = getAuthToken();
    const client = createApolloClient({ accessToken });
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    );
}

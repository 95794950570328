import * as React from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye,
    faPencil,
    faRuler,
    faLocationDot,
} from '@fortawesome/free-solid-svg-icons';

import { useWorkspaceContext } from "../context";


const MODES = [
    {
        id: "view",
        icon: faEye,
        label: "View",
    },
    {
        id: "measure",
        icon: faRuler,
        label: "Measure",
    },
    {
        id: "edit-geom",
        icon: faLocationDot,
        label: "Edit geometry",
    },
    {
        id: "edit-attrs",
        icon: faPencil,
        label: "Edit attributes",
    },
];


export default function ModeSelector() {
    const [isOpen, setOpen] = React.useState(false);
    const [selectedModeId, setSelectedMode] = React.useState("view");
    const selectedMode = MODES.find(x => x.id === selectedModeId);

    const workspaceContext = useWorkspaceContext();

    // console.log("Workspace data", workspaceContext.data);
    return (
        <Dropdown
            isOpen={isOpen}
            toggle={() => setOpen(st => !st)}
            direction="down"
        >
            <DropdownToggle caret>
                <FontAwesomeIcon icon={selectedMode.icon} />{" "}
                {selectedMode.label}
            </DropdownToggle>
            <DropdownMenu>
                {MODES.map(mode => (
                    <DropdownItem
                        key={mode.id}
                        active={mode.id === selectedModeId}
                        onClick={() => setSelectedMode(mode.id)}
                        >
                        <FontAwesomeIcon icon={mode.icon} />{" "}
                        {mode.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}

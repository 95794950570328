/**
 * Workspace context contains
 *
 * - Information about the workspace, synced with the API
 * - Local state, eg selected layer / tool -> only stored in the page, lost on refresh
 */

import * as React from "react";
import { useQuery, useMutation, gql } from "@apollo/client";

import { createDataStore } from "app/lib/datastore";


const WorkspaceReactContext = React.createContext(null);
WorkspaceReactContext.displayName = "WorkspaceContext";


export const DEFAULT_LOCAL_STATE = {
    viewSettings: {
        reticle: {
            show: false,
            color: "#000",
        }
    },
};


class WorkspaceContext {
    constructor({
        queryState, // { data, loading, error, refetch, ... }
        localState,  // [ state, setState ]
        // MUTATIONS...  // [ doMutation, { data, loading, error, ... } ]
    }) {
        this._queryState = queryState;
        this._localState = localState;
    }

    // Query state from API ------------------------------------------

    get data() {
        // console.log("Query state", this._queryState);
        return this._queryState?.data?.workspace || null;
    }

    get isLoading() {
        return this._queryState?.loading;
    }

    // TODO: add something to handle 404s

    refetch() {
        const refetch = this._queryState?.refetch;
        if (refetch) {
            refetch();
        }
    }

    // Local state ---------------------------------------------------

    get localState() {
        if (!this._localState) {
            return null;
        }
        const state = this._localState[0];
        return state;
    }

    setLocalState(...args) {
        if (!this._localState) {
            return null;
        }
        const setState = this._localState[1];
        return setState(...args);
    }

    // Mutations -----------------------------------------------------
}


export function WorkspaceContextProvider({ workspaceId, children }) {
    const queryState = useQuery(QUERY_WORKSPACE, { variables: { workspaceId }});
    const localState = React.useState(DEFAULT_LOCAL_STATE);
    const context = new WorkspaceContext({ queryState, localState });
    return (
        <WorkspaceReactContext.Provider value={context}>
            {children}
        </WorkspaceReactContext.Provider>
    );
}


const QUERY_WORKSPACE = gql`
    query($workspaceId: Int!) {
        workspace(workspaceId: $workspaceId) {
            id
            ownerId
            metadata {
                displayName
            }
        }
    }
`;


export function useWorkspaceContext() {
    return React.useContext(WorkspaceReactContext);
}

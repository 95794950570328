import * as React from "react";
import { Link } from "react-router-dom";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input,
    Form,
    FormGroup,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencilAlt,
    faBars,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Navigate } from "react-router-dom";

import ErrorMessage from "app/ui/error-message";
import LoadingMessage from "app/ui/loading-message";


export default function WorkspaceIndex() {
    const { data, loading, error, refetch } = useQuery(QUERY_WORKSPACES);
    return (
        <div style={{width: "90%", maxWidth: 800}} className="mx-auto my-3">
            <div className="mb-3 text-end">
                <CreateWorkspaceButton refetch={refetch} />
            </div>
            {error && <ErrorMessage retry={refetch} />}
            {loading && <LoadingMessage />}
            <WorkspacesList
                workspaces={data?.workspaces}
                refetch={refetch}
            />
        </div>
    );
}


function WorkspacesList({ workspaces }) {
    if (!workspaces) {
        return null;
    }
    if (!workspaces.length) {
        return (
            <div className="text-center p-5 text-muted">
                You have no workspaces yet!
            </div>
        );
    }
    return (
        <ListGroup>
            {workspaces.map(workspace =>
                <ListGroupItem
                    key={workspace.id}
                    className="d-flex flex-row justify-content-between"
                >
                    <div>
                        <Link
                            to={`/workspace/${workspace.id}`}
                            className="fs-4 text-decoration-none"
                        >
                            {workspace.displayName || "Untitled workspace"}
                        </Link>
                    </div>
                    <WorkspaceMenu />
                </ListGroupItem>
            )}
        </ListGroup>
    );
}


function CreateWorkspaceButton({ refetch }) {
    const [ isOpen, setOpen ] = React.useState(false);
    return (
        <>
            <Button color="success" onClick={() => setOpen(state => !state)}>
                Create workspace
            </Button>
            {isOpen &&
                <CreateWorkspaceModal
                    closeModal={() => setOpen(false)}
                    refetch={refetch}
            />}
        </>
    );
}


function CreateWorkspaceModal({ refetch, closeModal }) {

    const [formValues, setFormValues] = React.useState({
        displayName: "",
    });

    const [
        doCreateWorkspace,
        createWorkspaceStatus,
    ] = useMutation(MUTATION_CREATE_WORKSPACE);

    // Submit handler, creates a new workspace
    const onSubmit = (event) => {
        event.preventDefault();

        doCreateWorkspace({ variables: {
            data: {
                displayName: formValues.displayName,
            }
        }}).then(({ data }) => {
            if (data?.createWorkspace?.ok) {
                refetch();
            }
        });
    };

    // Redirect to newly created workspace, if needed
    if (createWorkspaceStatus?.data?.createWorkspace?.ok) {
        let {workspaceId} = createWorkspaceStatus.data.createWorkspace;
        return (
            <Navigate to={`/workspace/${workspaceId}`} />
        );
    }

    // Modal containing a form to create a new workspace
    return (
        <Modal isOpen toggle={closeModal}>
            <ModalHeader toggle={closeModal}>
                Create workspace
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={onSubmit}>
                    <FormGroup>
                        <Label for="input-workspace-display-name">
                            Name:
                        </Label>
                        <Input
                            id="input-workspace-display-name"
                            type="text"
                            value={formValues.displayName}
                            onChange={evt => setFormValues(values => ({
                                ...values,
                                displayName: evt.target.value,
                            }))}
                        />
                    </FormGroup>
                    <div>
                        <Button color="primary" type="submit">
                            Create workspace
                        </Button>
                        <Button color="secondary" className="ms-1" onClick={closeModal}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
}


function WorkspaceMenu() {
    const [menuOpen, setMenuOpen] = React.useState(false);
    return (
        <Dropdown isOpen={menuOpen} toggle={() => setMenuOpen(state => !state)}>

            <DropdownToggle color="link">
                <FontAwesomeIcon icon={faBars} />
            </DropdownToggle>

            <DropdownMenu end>

                <DropdownItem onClick={() => {}}>
                    <FontAwesomeIcon icon={faPencilAlt} />{" "}
                    Rename
                </DropdownItem>

                <DropdownItem onClick={() => {}}>
                    <FontAwesomeIcon icon={faTrash} />{" "}
                    Delete
                </DropdownItem>

            </DropdownMenu>
        </Dropdown>
    )
}


const QUERY_WORKSPACES = gql`
    query {
        workspaces {
            id
            displayName
        }
    }
`;


const MUTATION_CREATE_WORKSPACE = gql`
    mutation CreateWorkspace($data: WorkspaceInput!) {
        createWorkspace(data: $data) {
            ok
            workspaceId
            errorMessage
        }
    }
`;

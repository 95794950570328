const AUTH_TOKEN_NAME = "zengis-auth-token";


export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_NAME);
}

export function setAuthToken(value) {
    localStorage.setItem(AUTH_TOKEN_NAME, value);
}

export function delAuthToken() {
    localStorage.removeItem(AUTH_TOKEN_NAME);
}

import * as React from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLayerGroup,
    faEllipsis,
} from '@fortawesome/free-solid-svg-icons';

import { useWorkspaceContext } from "../context";



export default function LayerSelector() {
    const [isOpen, setOpen] = React.useState(false);
    const workspaceContext = useWorkspaceContext();
    // console.log("Workspace data", workspaceContext.data);
    return (
        <Dropdown
            isOpen={isOpen}
            toggle={() => setOpen(st => !st)}
            direction="down"
        >
            <DropdownToggle title="Layer menu" color="link">
                <FontAwesomeIcon icon={faEllipsis} />
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem>Layer settings</DropdownItem>
                <DropdownItem>Rename layer</DropdownItem>
                <DropdownItem>Delete layer</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

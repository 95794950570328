import * as React from "react";
import { useSearchParams } from "react-router-dom";
import Div100vh from 'react-div-100vh';
import { useMutation, gql } from "@apollo/client";
import { setAuthToken } from "app/lib/auth-token";


export default function SignupPageWrapper() {
    return (
        <Div100vh className="d-flex flex-column justify-content-center align-items-center">
            <LoginPage />
        </Div100vh>
    );
}


function LoginPage() {
    const [searchParams] = useSearchParams();
    const loginToken = searchParams.get("token");
    const [doLogin, { data, loading, error }] = useMutation(GQL_LOGIN);

    React.useEffect(
        () => {
            doLogin({ variables: { token: loginToken } })
                .then(({ data }) => {
                    // console.log("RESULT", data);
                    if (data?.login?.ok) {
                        const { token } = data.login;
                        setAuthToken(token);
                        // Force reload the page
                        window.location.href = "/";
                    }
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loginToken],
    );

    if (loading) {
        return (
            <div>
                Logging you in...
            </div>
        );
    }


    if (data?.login?.ok) {
        return (
            <div>
                <h1>Success</h1>
            </div>
        );
    }

    return (
        <div>Error</div>
    );
}


const GQL_LOGIN = gql`
    mutation Login($token: String!) {
        login(token: $token) {
            ok
            token
            errorMessage
        }
    }
`;

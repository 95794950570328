import * as React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { copyText } from "app/lib/clipboard";


export default function CopyButton({ value, copiedLabel, ...props }) {

    const [ copied, setCopied ] = React.useState(false);

    let newProps = {
        ...props,
        onClick() {
            copyText(value);
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
        },
    };

    if (copied) {
        newProps.color = "success";
        newProps.children = copiedLabel;
    }

    return (
        <Button {...newProps} />
    );
}


CopyButton.defaultProps = {
    children: <FontAwesomeIcon icon={faClipboard} />,
    copiedLabel: <><FontAwesomeIcon icon={faClipboard} /> Copied!</>,
};

import * as Turf from "@turf/turf";


const DEFAULT_OPTIONS = {
    // Create segments of this length, in Km
    segmentLengthKm: 50,
};


/**
 * Convert features to geodesic (Great Circle) arcs.
 */
export default function makeGeodesicGeojson(obj, _options) {
    let options = {
        ...DEFAULT_OPTIONS,
        ..._options,
    };
    return makeGeodesicGeojsonObject(obj, options);
}


function makeGeodesicGeojsonObject(obj, options) {
    if (obj.type === "FeatureCollection") {
        return makeGeodesicFeatureCollection(obj, options);
    }
    if (obj.type === "Feature") {
        return makeGeodesicFeature(obj, options);
    }
    return obj;
}


function makeGeodesicFeatureCollection(obj, options) {
    return {
        ...obj,
        features: obj.features.map(subObj => makeGeodesicGeojsonObject(subObj, options)),
    };
}


function makeGeodesicFeature(obj, options) {
    if (obj.geometry.type === "LineString") {
        return makeGeodesicLineString(obj, options);
    }
    return obj;
}


/**
 * Convert a GeoJSON LineString to a geodesic arc.
 *
 * Args:
 *     obj:
 *     options:
 *         segmentLengthKm: segment length
 */
export function makeGeodesicLineString(obj, options) {
    const { segmentLengthKm } = options;

    let newCoordinates = [];
    Turf.segmentEach(obj, (segment) => {
        // Convert the segment as needed
        newCoordinates.push(...makeGeodesicLineSegment(segment, segmentLengthKm));
    });

    return {
        ...obj,
        geometry: {
            ...obj.geometry,
            coordinates: newCoordinates,
        },
    };
}


/**
 * Convert a line segment to geodesic arc
 */
export function makeGeodesicLineSegment(obj, segmentLengthKm) {
    let length = Turf.length(obj, { units: "kilometers" });
    if (length <= segmentLengthKm) {
        return obj.geometry.coordinates;
    }
    let segmentCount = Math.floor(length / segmentLengthKm);
    let arc = [];

    for (let i = 0; i < segmentCount; i++) {

        let nextStep = Turf.along(
            obj,
            // *******************************************************
            // HACK: Setting the initial distance to a very small
            // number different from zero, forces the algorithm to
            // recalculate the starting coordinate as well; this ensures
            // correct drawing of segments that cross the antimeridian
            // line.
            // Btw 1e-10 km is 1/10 of a mm, so should be close enough
            // *******************************************************
            (i === 0 ? 1e-10 : i * segmentLengthKm),
            'kilometers');

        arc.push(nextStep.geometry.coordinates);
    }

    return arc;
}

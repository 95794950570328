import * as React from "react";
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    useParams,
    Link,
    Navigate,
} from "react-router-dom";
import Div100vh from 'react-div-100vh';
// import SiteMeta from "app/ui/site-meta";
import SiteHeader from "app/ui/site-header";
import WorkspaceView from "app/ui/workspace-view";
import ConfiguredApolloProvider from "app/lib/apollo-provider";
import { TokenContextProvider } from "app/ui/token-context";
import SignupPage from "app/pages/signup";
import LoginPage from "app/pages/login";
import WorkspaceIndex from "app/pages/workspace-index";
import AccountPage from "app/pages/account";
import { useTokenContext } from "app/ui/token-context";


const router = createBrowserRouter([
    {path: "/login", element: <LoginPage />},
    {path: "/signup", element: <SignupPage />},
    {
        path: "/",
        element: <Root />,
        children: [
            {
                index: true,
                element: <IndexPage />,
            },
            {
                path: "workspaces",
                index: true,
                element: <WorkspaceIndex />,
            },
            {
                path: "workspace/:workspaceId",
                element: <Workspace />,
                children: [
                    {
                        path: "settings",
                        element: <WorkspaceSettings />,
                    }
                ]
            },
            {
                path: "account",
                element: <AccountPage />,
            },
        ],
    },
]);


export default function Routes() {
    return (
        <ConfiguredApolloProvider>
            <TokenContextProvider>
                <RouterProvider router={router} />
            </TokenContextProvider>
        </ConfiguredApolloProvider>
    );
}


function IndexPage() {
    const { tokenInfo } = useTokenContext();

    if (!tokenInfo) {
        // Request still loading
        // TODO: return some nicer placeholder?
        return "Loading...";
    }

    if (!tokenInfo.isAuthenticated) {
        // Not authenticated or invalid token,
        // Redirect to signup page.
        return <Navigate to="/signup" />;
    }

    // Redirect to workspaces page.
    return <Navigate to="/workspaces" />;
}


function Layout({children}) {
    return (
        <Div100vh className="d-flex flex-column">
            <SiteHeader />
            <div className="d-flex flex-column flex-grow-1">
                {children}
            </div>
            <SiteFooter />
        </Div100vh>
    );
}


function SiteFooter() {
    let year = (new Date()).getFullYear();
    return (
        <div className="text-end text-muted">
            &copy; {year} ZenGIS
        </div>
    );
}


function Root() {
    return (
        <Layout>
            <Outlet />
        </Layout>
    );
}


function Workspace() {
    let params = useParams();
    let { workspaceId } = params;
    return <WorkspaceView workspaceId={workspaceId} />;
}


function WorkspaceSettings() {
    return (
        <div>
            <h2>Workspace settings (modal)</h2>
            <Link to="..">Close</Link>
        </div>
    );
}

import * as React from "react";
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    useParams,
    Link,
} from "react-router-dom";
import Div100vh from 'react-div-100vh';
import { Label, Input, Button } from "reactstrap";
import { ReactComponent as Logo } from "images/logo.svg";
import { PRODUCT_TITLE } from "app/config";
import { useMutation, gql } from "@apollo/client";


export default function SignupPageWrapper() {
    return (
        <Div100vh className="d-flex flex-column justify-content-center align-items-center">
            <SignupPage />
        </Div100vh>
    );
}


function SignupPage() {
    const [formValues, setFormValues] = React.useState({});
    const [doSignup, { data, loading, error }] = useMutation(GQL_SIGNUP);

    const onSubmit = () => {
        // console.log("Signing up with email:", formValues.email);
        doSignup({
            variables: {
                data: {
                    email: formValues.email,
                },
            },
        });
    };

    if (loading) {
        return (
            <div>
                Logging you in...
            </div>
        );
    }


    if (data?.signup?.ok) {
        return (
            <div>
                <h1>Check your email</h1>
            </div>
        );
    }

    return (
        <SignupForm {...{ formValues, setFormValues, onSubmit }} />
    );
}


function SignupForm({ formValues, setFormValues, onSubmit }) {
    const isValid = React.useMemo(
        () => isValidEmailAddress(formValues.email),
        [formValues]
    );
    return (
        <div style={{ width: "100%", maxWidth: "600px" }}
            className="d-flex flex-column align-items-center">
            <div className="d-flex flex-row align-items-center justify-content-center mb-5">
                <div className="fs-3 me-2">
                    <Logo width="1em" height="1em" viewBox="0 0 512 512" />
                </div>
                <div>
                    <h1 className="fs-3 m-0">
                        {PRODUCT_TITLE}{" "}
                        <span style={{ fontWeight: "normal" }}>Signup / Login</span>
                    </h1>
                </div>
            </div>
            <form onSubmit={evt => { evt.preventDefault(); onSubmit(); }}>
                <div className="text-center mb-3" style={{ maxWidth: 500 }}>
                    <Label>Email address:</Label>
                    <Input
                        style={{ width: 400 }}
                        type="email"
                        name="email"
                        value={formValues.email || ""}
                        onChange={event => setFormValues(values => ({
                            ...values,
                            email: event.target.value,
                        }))}
                    />
                </div>
            </form>
            <Button disabled={!isValid} color="primary" onClick={() => onSubmit()}>
                Send login link
            </Button>
        </div>
    );
}


function isValidEmailAddress(text) {
    return /.+@.+/.test(text);
}


const GQL_SIGNUP = gql`
    mutation Signup($data: SignupData!) {
        signup(data: $data) {
            ok
            errorMessage
        }
    }
`;

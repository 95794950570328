import * as React from "react";
import {
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGlobe,
    faTrashAlt,
    faArrowAltCircleUp,
    faMapMarkerAlt,
    faCog,
    faPlusCircle,
    faPencilAlt,
    faBars,
    faQuestionCircle,
    faDownload,
    faUpload,
    faLayerGroup,
    faMap,
    faTimes,
    faCrosshairs,
    faEllipsis,
    faCopy,
    faClipboard,
    faCompass,
    faLocationDot,
    faMound,
} from '@fortawesome/free-solid-svg-icons';

import CopyButton from "app/ui/copy-button";
import {
    formatLatLonPlain,
    formatBearing,
    formatLatLonDMS,
    formatLatLonDM,
} from "app/lib/formatting";
import { copyText } from "app/lib/clipboard";

import ReticleSettingsButton from "../toolbar/reticle-settings";
import { WorkspaceContextProvider, useWorkspaceContext } from "../context";
import { useDefaultMap } from "../map";


export default function WorkspaceStatusbar() {
    const map = useDefaultMap();
    let [mapState, setMapState] = React.useState(null);

    // console.log("Current map:", map);

    React.useEffect(() => {
        if (!map) {
            // TODO: issue warning!
            return;
        }
        // console.log("Attaching render event handler");
        map.on("render", () => {
            setMapState({
                center: map.getCenter(),
                bearing: map.getBearing(),
                pitch: map.getPitch(),
            });
        });
    }, [map]);

    if (!mapState) {
        return null;
    }

    const resetBearing = () => map.setBearing(0);
    const resetPitch = () => map.setPitch(0);

    let { center, bearing, pitch } = mapState;

    // Bearing is returned in range -180 to 180.
    // We want to convert it to the 0 to 360 range for display.
    if (bearing < 0) {
        bearing += 360;
    }

    return (
        <div className="m-1" style={{ position: "absolute", left: 0, bottom: 0 }}>
            <div
                className="d-flex flex-row"
                style={{ pointerEvents: "auto" }}
            >

                <div className="me-1">
                    <CoordinatesDisplay center={center} />
                </div>

                {(bearing !== 0) && (
                    <div
                        className="px-3 py-2 rounded me-1"
                        style={{
                            background: "rgba(0,0,0,.5)",
                            pointerEvents: "auto",
                            cursor: "pointer",
                        }}
                        title="Bearing (click to reset)"
                        onClick={resetBearing}
                    >
                        <FontAwesomeIcon icon={faCompass} />
                        {" "}{formatBearing(bearing)}
                    </div>
                )}

                {(pitch !== 0) && (
                    <div
                        className="px-3 py-2 rounded me-1"
                        style={{
                            background: "rgba(0,0,0,.5)",
                            pointerEvents: "auto",
                            cursor: "pointer",
                        }}
                        title="Pitch (click to reset)"
                        onClick={resetPitch}
                    >
                        <FontAwesomeIcon icon={faMound} />
                        {" "}{formatBearing(pitch)}
                    </div>
                )}

                <div className="me-1">
                    <ReticleSettingsButton />
                </div>

            </div>
        </div>
    );
}


function CoordinatesDisplay({ center }) {

    const COORD_FORMATS = [
        {id: "dec", formatter: formatLatLonPlain},
        {id: "dm", formatter: formatLatLonDM},
        {id: "dms", formatter: formatLatLonDMS},
    ];

    const [isOpen, setOpen] = React.useState(false);
    const [coordFormatId, setCoordFormatId] = React.useState("dec");

    const coordFormat = COORD_FORMATS.find(x => x.id === coordFormatId) || COORD_FORMATS[0];
    let coordsText = coordFormat.formatter([center.lat, center.lng]);

    const iconStyle = {
        cursor: "default",
    }

    const coordsBtnStyle = {
        background: "rgba(0,0,0,.5)",
    };

    return (
        <>
            <Dropdown
                group
                direction="up"
                isOpen={isOpen}
                toggle={() => setOpen(st => !st)}
            >
                <Button tag={"span"} style={iconStyle}>
                    <FontAwesomeIcon icon={faLocationDot} />
                </Button>
                <DropdownToggle caret style={coordsBtnStyle}>
                    <span style={{ minWidth: 250, display: "inline-block" }}>
                        <code>{coordsText}</code>
                    </span>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Coordinates format</DropdownItem>
                    {COORD_FORMATS.map(fmt => (
                        <DropdownItem
                            key={fmt.id}
                            active={coordFormatId === fmt.id}
                            onClick={() => setCoordFormatId(fmt.id)}
                        >
                            <code>{fmt.formatter([center.lat, center.lng])}</code>
                        </DropdownItem>
                    ))}
                    <DropdownItem divider />
                    <DropdownItem header>Actions</DropdownItem>
                    <DropdownItem onClick={() => copyText(coordsText)}>
                        <FontAwesomeIcon icon={faClipboard} />
                        {" "}Copy to clipboard
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

import * as React from "react";
import { useQuery, gql } from "@apollo/client";


const TokenContext = React.createContext({
    tokenInfo: null,
    loading: false,
    error: null,
});


export function TokenContextProvider({ children }) {
    const { data, loading, error } = useQuery(gql`
        query {
            tokenInfo {
                isAuthenticated
                userId
            }
        }
    `);

    let context = {
        tokenInfo: data?.tokenInfo || null,
        loading,
        error,
    };

    return (
        <TokenContext.Provider value={context}>
            {children}
        </TokenContext.Provider>
    );
}


export function useTokenContext() {
    return React.useContext(TokenContext);
}
